
require('bootstrap');

import $ from "jquery";
window.$ = window.jQuery = $;

jQuery(function () {
    $('.transferstatusselector select').on('change', function () {
        if ($(this).attr('data-watch') == $(this).val()) {
            $('.next_offer_status').removeClass('d-none');
            $('.next_request_status').removeClass('d-none');
        } else {
            $('.next_offer_status').addClass('d-none');
            $('.next_request_status').addClass('d-none');
        }
    });

    $('.transferstatusselector select').trigger('change');

    $('.next_offer_status select').on('change', function (e) {
        //console.log('IN OFFER', e.isTrigger)
        if ($(this).attr('data-watch') == $(this).val()) {
            //console.log('SONO REJECTED, DEVO RIGETTARE ANCHE RICHIESTE');
            $('.next_request_status select').val($(this).attr('data-set')).trigger('change');
        }
    });

    $('.next_request_status select').on('change', function (e) {
        //console.log('IN REQEUST', e.isTrigger)
        if ($(this).attr('data-watch') == $('.next_offer_status select').val()) {
            //console.log('E\' REJECTED, DEVO RIGETTARE ANCHE ME');
            $(this).val($(this).attr('data-set'));
        }
    });

    $('.btn-requireconfirm').on('click', function(e) {
        return confirm($(this).attr('data-confirm-message'));
    });

    $('.platevalueholder').on('change', function () {
        const regex = /^([A-Z]{2}[0-9]{3}[A-Z]{2})$/;
        const value = $(this).val().toUpperCase();
        $(this).val(value);

        const valid = regex.test(value);

        if (valid) {
            $('.plateinforetriever').show();
        } else {
            $('.plateinforetriever').hide();
        }
    });

    $('.plateinforetriever').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        getInfocarAMByPlate($('.platevalueholder').val());
    });

    function getInfocarAMByPlate(plate) {
        const scopeprefix = $('.platevalueholder').attr('data-scopeprefix');
        const scopesuffix = $('.platevalueholder').attr('data-scopesuffix');

        const options = {
            type: "GET",
            dataType: "json",
            url: '/infocar/plate/' + plate,

            success: function (j) {
                getAnagraficalDataByInfocarAM(j)
                getTechnicalDataByInfocarAM(j)
            },
            error: function (j) {
                console.log('error', j);

                $('[name="' + scopeprefix + 'make_infocar_codice_marca' + scopesuffix + '"]').prop('disabled', true);
                $('[name="' + scopeprefix + 'model_infocar_codice_modello' + scopesuffix + '"]').prop('disabled', true);
                $('[name="' + scopeprefix + 'setup_infocar_codice_infocar_am' + scopesuffix + '"]').prop('disabled', true);
            }
        };

        $.ajax(options);
    }

    function getAnagraficalDataByInfocarAM(infocaram) {
        const scopeprefix = $('.platevalueholder').attr('data-scopeprefix');
        const scopesuffix = $('.platevalueholder').attr('data-scopesuffix');
        const options = {
            type: "GET",
            dataType: "json",
            url: '/infocar/anagraficalData/' + infocaram,

            success: function (j) {
                console.log('success', j);
                $('[name="' + scopeprefix + 'make' + scopesuffix + '"]').val(j.DescrizioneMarca).prop('readonly', true);
                $('[name="' + scopeprefix + 'make_infocar_codice_marca' + scopesuffix + '"]').val(j.CodiceMarca).prop('readonly', true);
                $('[name="' + scopeprefix + 'model' + scopesuffix + '"]').val(j.DescrizioneModello).prop('readonly', true);
                $('[name="' + scopeprefix + 'model_infocar_codice_modello' + scopesuffix + '"]').val(j.CodiceModello).prop('readonly', true);
                $('[name="' + scopeprefix + 'setup' + scopesuffix + '"]').val(j.DescrizioneAllestimento).prop('readonly', true);
                $('[name="' + scopeprefix + 'setup_infocar_codice_infocar_am' + scopesuffix + '"]').val(j.CodiceInfocarAM).prop('readonly', true);
            },
            error: function (j) {
                console.log('error', j);
            }
        };

        $.ajax(options);
    }

    function getTechnicalDataByInfocarAM(infocaram) {
        const scopeprefix = $('.platevalueholder').attr('data-scopeprefix');
        const scopesuffix = $('.platevalueholder').attr('data-scopesuffix');
        const options = {
            type: "GET",
            dataType: "json",
            url: '/infocar/technicalData/' + infocaram,

            success: function (j) {
                console.log('success', j);

                $('[name="' + scopeprefix + 'displacement' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Motore.Cilindrata).prop('readonly', true);
                $('[name="' + scopeprefix + 'emissionstandard' + scopesuffix + '"]').val(j.DatiBase.Normativa).prop('readonly', true);
                $('[name="' + scopeprefix + 'fuelconsumption' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Consumo3).prop('readonly', true);
                $('[name="' + scopeprefix + 'acceleration0100' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Accelerazione0100).prop('readonly', true);
                $('[name="' + scopeprefix + 'emission' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Emissione3).prop('readonly', true);
                $('[name="' + scopeprefix + 'horsepower' + scopesuffix + '"]').val(j.MotorePrestazioni[0].PotenzaCV).prop('readonly', true);
                $('[name="' + scopeprefix + 'kW' + scopesuffix + '"]').val(j.MotorePrestazioni[0].PotenzaKW).prop('readonly', true);
                $('[name="' + scopeprefix + 'powersupply' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Motore.Combustibile).prop('readonly', true);
                $('[name="' + scopeprefix + 'seats' + scopesuffix + '"]').val(j.DatiBase.NumeroPosti).prop('readonly', true);
                $('[name="' + scopeprefix + 'doors' + scopesuffix + '"]').val(j.DatiBase.NumeroPorte).prop('readonly', true);
                $('[name="' + scopeprefix + 'gearbox' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Cambio).prop('readonly', true);
                $('[name="' + scopeprefix + 'traction' + scopesuffix + '"]').val(j.MotorePrestazioni[0].Trazione).prop('readonly', true);
            },
            error: function (j) {
                console.log('error', j);
            }
        };

        $.ajax(options);
    }

    function guidedOfferEditor() {
        $('[tab_name="vehicle"]').parent().parent().reverseChildren();
        $('[tab_name="vehicle"]').parent().parent().next().reverseChildren();
        $('[tab_name="vehicle"]').trigger('click');

        const required = [
            'vehicle.make', 'vehicle.model', 'vehicle.setup', 'vehicle.immatriculation', 'vehicle.km_travelled', 'vehicle.color', 'vehicle.vehicle_type_id', 'vehicle.customer',
            'contract.name', 'contract.valid_from', 'contract.valid_to', 'contract.advance', 'contract.monthly_rate', 'contract.km_year', 'contract.customer', 'contract.leaser', 'contract.file_contract_documentation_id',
            'customer', 'person_id', 'partner'
        ];

        for (let i = 0; i < required.length; i ++) {
            $('[bp-field-name="'+required[i]+'"]').addClass('required');
        }

        addFormValidityCheck();
        coherentCustomerData();
        coherentPersonData();
        handleOfferQuotations();
        // $(document).on('change', 'input, select', (e) => {
        //     console.log('change', e.target);
        // })
    }

    function coherentCustomerData() {
        document.iscustomerchanging = false;
        var customerselects = ['vehicle[customer_id]', 'contract[customer_id]', 'customer'];
        for(let id in customerselects) {
            $('[name="'+customerselects[id]+'"]').on('change', (e) => {
                if (document.iscustomerchanging) {
                    return;
                } else {
                    document.iscustomerchanging = true;
                }

                let $this   = $(e.target);
                let val     = $this.val();
                let label   = $this.children('option:selected').text();

                for(let id in customerselects) {
                    if (customerselects[id] == $this.attr('name')) continue;
                    console.log(customerselects[id], $('[name="'+customerselects[id]+'"]').prop('nodeName'));
                    const type = $('[name="'+customerselects[id]+'"]').prop('nodeName');

                    if (type == 'INPUT') {
                        $('[name="'+customerselects[id]+'"]').val(val);
                    } else if (type == 'SELECT') {
                        setTimeout(() => {
                            var option = new Option(label, val, true, true);
                            $('[name="'+customerselects[id]+'"]').append(option).trigger('change');
                        }, 500);
                    }
                }

                setTimeout(() => {
                    document.iscustomerchanging = false;
                }, 1000)
            })
        }
    }
    function coherentPersonData() {
        document.ispersonchanging = false;
        var personselects = ['vehicle[person_id]', 'person_id'];
        for(let id in personselects) {
            $('[name="'+personselects[id]+'"]').on('change', (e) => {
                if (document.ispersonchanging) {
                    return;
                } else {
                    document.ispersonchanging = true;
                }

                let $this   = $(e.target);
                let val     = $this.val();
                let label   = $this.children('option:selected').text();

                for(let id in personselects) {
                    if (personselects[id] == $this.attr('name')) continue;
                    console.log(personselects[id], $('[name="'+personselects[id]+'"]').prop('nodeName'));
                    const type = $('[name="'+personselects[id]+'"]').prop('nodeName');

                    if (type == 'INPUT') {
                        $('[name="'+personselects[id]+'"]').val(val);
                    } else if (type == 'SELECT') {
                        setTimeout(() => {
                            var option = new Option(label, val, true, true);
                            $('[name="'+personselects[id]+'"]').append(option).trigger('change');
                        }, 500);
                    }
                }

                setTimeout(() => {
                    document.ispersonchanging = false;
                }, 1000)
            })
        }
    }

    function handleOfferQuotations() {
        var datafields = ['contract[valid_to]', 'contract[monthly_rate]'];
        for(let id in datafields) {
            $('[name="'+datafields[id]+'"]').on('change', (e) => {
                
                const valid_to = $('[name="contract[valid_to]"]').val();
                const monthly_rate = $('[name="contract[monthly_rate]"]').val();

                if (valid_to != "" && monthly_rate != "") {
                    const optionspublishing = {
                        type: "GET",
                        dataType: "json",
                        url: '/quotation/offer/publishing/' + monthly_rate,
            
                        success: function (j) {
                            console.log('success', j);
                            $('[name="price_publishing"]').val(j.price);
                        },
                        error: function (j) {
                            console.log('error', j);
                        }
                    };
            
                    $.ajax(optionspublishing);


                    const optionstransfer = {
                        type: "GET",
                        dataType: "json",
                        url: '/quotation/offer/transfer/' + monthly_rate + '/' +valid_to,
            
                        success: function (j) {
                            console.log('success', j);
                            $('[name="price_transfer"]').val(j.price);
                        },
                        error: function (j) {
                            console.log('error', j);
                        }
                    };
            
                    $.ajax(optionstransfer);
                }
            });
        }
    }

    function addFormValidityCheck() {
        const form = $('[tab_name="vehicle"]').closest('form');
        
        form[0].checkValidity = () => {
            var valid = true;
            $('.form-control').each(function () {
                let nonrequired = ['contract[id]', 'vehicle[id]'];

                if ($(this).is(':disabled')) {
                    $(this)[0].setCustomValidity('');
                } else if ($(this).val() === '' && nonrequired.indexOf($(this).attr('name')) < 0) {
                    console.log('Errore in ', $(this).attr('name'));
                    
                    $(this)[0].setCustomValidity('Missing or invalid value');
                    valid = false
                } else {
                    $(this)[0].setCustomValidity('');
                }
            });


            if ($('[name="tac_publishing_accepted"]').val() == "0") {
                valid = false;
                $('[name="tac_publishing_accepted"]').next().next().css('borderColor', 'red');
            } else {
                $('[name="tac_publishing_accepted"]').next().next().css('borderColor', 'inherit');
            }

            if ($('[name="tac_transfer_accepted"]').val() == "0") {
                valid = false;
                $('[name="tac_transfer_accepted"]').next().next().css('borderColor', 'red');
            }

            console.log('form is valid?', valid);
            return valid;
        }
    }

    if ($('.offer_crud_new').length > 0) {
        guidedOfferEditor();
    }

});

$.fn.reverseChildren = function () {
    return this.each(function () {
        var $this = $(this);
        $this.children().each(function () { $this.prepend(this) });
    });
};